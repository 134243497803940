import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout/Layout'
import {Container, Row, Col} from 'react-bootstrap'
import '../styles/pages/contacts.scss'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import PhoneIcon from '../../static/svg/phone.svg'
import MailIcon from '../../static/svg/mail.svg'
import ScheduleIcon from '../../static/svg/schedule.svg'
import LocationIcon from '../../static/svg/location_on.svg'
import WorkIcon from '../../static/svg/work-in.svg'
import Seo from '../components/Seo/Seo'
import normalizePhone from '../functions/phone-normalizer'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'


function ContactsPage({data, pageContext}) {
    const {
        breadcrumb: { crumbs },
      } = pageContext
      const title = 'Контакты';
    return (
        <Layout>
            <Seo title={title}/>
            <Container className='content' fluid="xl">
                <Row>
                    <Col >
                    <Breadcrumb
                        crumbs={crumbs}
                        crumbLabel={title}
                      />
                        <h1 className="page--heading">{title}</h1>
                        <p className="page--desc">
                            Решили сотрудничать? Свяжитесь любым удобным вам способом, или приезжайте прямиком на производство. <br/> Мы ответим на любые возникшие вопросы.
                        </p>

                        <Row>
                            <Col xs={'auto'} xs={12} sm={4} md={3} lg={2} className="contacts-item--wrapper contacts-item--wrapper_left">
                                <div className="contacts-item">
                                    
                                    <a href={"tel:" + data.contentfulCompanyContacts.phones[0]} className="no-margin contacts-item--heading contacts-item--link">
                                        <img src={PhoneIcon} alt="Телефон" className="contacts-item--icon"/>
                                        {normalizePhone(data.contentfulCompanyContacts.phones[0])}</a>
                                    <a href={"tel:" + data.contentfulCompanyContacts.phones[1]} className="contacts-item--heading contacts-item--link contacts-item--link_tel2">{normalizePhone(data.contentfulCompanyContacts.phones[1])}</a>
                                    <p className="contacts-item--desc">
                                        Отвечаем 24/7
                                    </p>
                                </div>

                            </Col>
                            <Col xs={'auto'} xs={12} sm={4} md={3} lg={true} className="contacts-item--wrapper">
                                <div className="contacts-item">
                                    
                                    <a href={'mailto:' + data.contentfulCompanyContacts.mail} className="contacts-item--heading contacts-item--link">
                                        <img src={MailIcon} alt="Почта" className="contacts-item--icon"/>{data.contentfulCompanyContacts.mail}
                                    </a>
                                    <p className="contacts-item--desc">
                                        Отправьте свою заявку напрямую или пишите ваши предложения по сотрудничеству
                                    </p>
                                </div>

                            </Col>
                            <Col xs={'auto'} xs={12} sm={4} md={3} lg={true} className="contacts-item--wrapper">
                            <div className="contacts-item">
                                
                                <p className="contacts-item--heading"><img src={ScheduleIcon} alt="Режим работы" className="contacts-item--icon"/>Режим работы</p>
                                <p className="contacts-item--desc no-margin">
                                    пн-пт: {data.contentfulCompanyContacts.mondayFridayHours}
                                </p>
                                <p className="contacts-item--desc no-margin">
                                    сб: {data.contentfulCompanyContacts.saturdayHours}
                                </p>
                            </div>

                            </Col>
                            <Col xs={'auto'} xs={12} sm={4} md={3} lg={true} className="contacts-item--wrapper">
                            <div className="contacts-item">
                                
                                <p className="contacts-item--heading"><img src={LocationIcon} alt="Мы находимся по адресу" className="contacts-item--icon"/>Мы находимся по адресу</p>
                                <p className="contacts-item--desc">
                                    {data.contentfulCompanyContacts.address}
                                </p>
                            </div>

                            </Col>
                            <Col xs={'auto'} xs={12} sm={4} md={3} lg={true}  className="contacts-item--wrapper contacts-item--wrapper_right">
                            <div className="contacts-item">
                                
                                <p className="contacts-item--heading"><img src={WorkIcon} alt="Работа в компании" className="contacts-item--icon"/>Работа в компании</p>
                                
                                <p className="contacts-item--desc no-margin">
                                    Специалист?<br/> Присоединяйтесь к нашей команде
                                </p>
                                <Link to='/about#vacancies' className="contacts-item--link_vacancies">Смотреть вакансии</Link>
                                
                            </div>
                            </Col>
                        </Row> 
                                

                    </Col>
                </Row>
                </Container>

                
                <div className="contacts--bg">
                    <Container fluid="xl">
                        <Row>
                            <Col xs={{span: 12, order: 2}} md={{span: 6, order: 1}}>
                                <iframe title='y-map' src="https://yandex.ru/map-widget/v1/?um=constructor%3A70e2f6e88c65edb89d92b16d1ada1b0f53fb652cc00370ca0a7657d9c9546077&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
                            </Col>
                            <Col className='mb-5' xs={{span: 12, order: 1}} md={{span: 6, order: 2}} >
                                <div className="contacts-how-to-get">
                                    <p className="contacts-how-to-get--title">{data.contentfulCompanyContacts.extra.title}</p>
                                    <p className="contacts-how-to-get--desc">{data.contentfulCompanyContacts.extra.description.description}</p>                                
                                    <Img className="contacts-how-to-get--img" fluid={data.contentfulCompanyContacts.extra.image.fluid} alt={data.contentfulCompanyContacts.extra.image.title}/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
        </Layout>
    )
}


export default ContactsPage

export const contactsPageQuery = graphql`
query contactsPageQuery {
    contentfulCompanyContacts {
        title
    mail
    address
    phones
    mondayFridayHours
    saturdayHours
    extra {
      title
      description {
        description
      }
      image {
        fluid(maxWidth: 800) {
          base64
          tracedSVG
          srcWebp
          srcSetWebp
        }
        title
      }
    }
}
}

`